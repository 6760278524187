'use client'
import { ArrowRight, MonitorPlay, Search, DollarSign, Star } from 'lucide-react';
import Image from 'next/image';
import { FeatureCard } from './components/feature-card';
import { TechFeatureCard } from './components/tech-feature-card';
import { SuccessStoryCard } from './components/success-story-card.tsx';
import { FAQItem } from './components/faq-item';
import { StepCard } from './components/step-card';
import { PricingTable } from './components/pricing-table';
import { Link } from '@repo/i18n/navigation';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';

export default function LandingPage() {
  const t= useTranslations();
  const pricingPlans = [
    {
      name: 'Trial',
      siteCount: 'Single site',
      pricePerSite: 99,
      originalPrice: 159,
      discount: 40,
      totalPrice: 99,
      aiLevel: 'Basic',
      supportLevel: 'Basic',
      updateFrequency: 'Monthly',
      buttonText: 'Try Now'
    },
    {
      name: 'Essential',
      siteCount: '3 sites',
      pricePerSite: 151,
      originalPrice: 159,
      discount: 5,
      totalPrice: 453,
      aiLevel: 'Standard',
      supportLevel: 'Priority',
      updateFrequency: 'Weekly',
      buttonText: 'Get Started'
    },
    {
      name: 'Professional',
      siteCount: '8 sites',
      pricePerSite: 143,
      originalPrice: 159,
      discount: 10,
      totalPrice: 1145,
      aiLevel: 'Advanced',
      supportLevel: 'Premium',
      updateFrequency: 'Weekly',
      buttonText: 'Choose Pro',
      isPopular: true
    },
    {
      name: 'Enterprise',
      siteCount: '15 sites',
      pricePerSite: 127,
      originalPrice: 159,
      discount: 20,
      totalPrice: 1908,
      aiLevel: 'Enterprise',
      supportLevel: '24/7 VIP',
      updateFrequency: 'Priority',
      buttonText: 'Contact Sales'
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Core Features */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">
            Why Choose Faweb?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard 
              icon={<Search className="h-8 w-8 text-blue-600" />}
              title="AI Trend Discovery"
              description="Predict viral games 7 days before they trend. Our AI analyzes global gaming data to identify the next big hits with remarkable accuracy."
            />
            <FeatureCard 
              icon={<MonitorPlay className="h-8 w-8 text-blue-600" />}
              title="30-Min Deployment"
              description="Launch SEO-optimized gaming sites in 30 minutes. Built-in templates, automated content generation, and one-click deployment to save your time."
            />
            <FeatureCard 
              icon={<DollarSign className="h-8 w-8 text-blue-600" />}
              title="Scale & Profit"
              description="Built-in Google Ads, CPS networks, and automated content updates. Scale your sites with batch deployment and maximize ROI with minimal effort."
            />
          </div>
        </div>
      </div>

      {/* Technical Advantages */}
      <div className="py-20 bg-blue-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">
            Advanced Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <TechFeatureCard 
              title="AI-Powered Development"
              features={[
                "Automatic SEO optimization",
                "Multi-language support",
                "Smart content generation",
                "Auto-generated sitemap",
                "Built-in analytics integration"
              ]}
            />
            <TechFeatureCard 
              title="Complete Website System"
              features={[
                "Professional game templates",
                "Custom navigation menus",
                "Blog system included",
                "Auto-generated FAQ",
                "User comment system"
              ]}
            />
            <TechFeatureCard 
              title="Monetization Tools"
              features={[
                "Google Ads integration",
                "CPS advertising support",
                "Publishers ad network",
                "Revenue tracking",
                "Performance analytics"
              ]}
            />
          </div>
        </div>
      </div>

      {/* Success Stories */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">
            Success Stories
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <SuccessStoryCard 
              title="Game Marketing Expert"
              story="Using Faweb's AI trend analysis, I identified an upcoming viral game a week before it peaked. Website was up in 30 minutes, and ROI hit 300% within the first month."
              metrics={{
                timeToMarket: "30 minutes",
                monthlyVisits: "50K+",
                roi: "300%"
              }}
            />
            <SuccessStoryCard 
              title="Indie Developer"
              story="As a solo developer, I needed a quick way to promote my games. Faweb helped me create 5 gaming sites in one afternoon, complete with SEO and monetization."
              metrics={{
                sites: "5 sites",
                setupTime: "1 afternoon",
                monthlyRevenue: "$2K+"
              }}
            />
            <SuccessStoryCard 
              title="Digital Marketer"
              story="The batch deployment feature is a game-changer. I manage 20+ gaming sites with minimal effort. The AI content generation saves me hours of work every week."
              metrics={{
                activeSites: "20+",
                contentSaved: "20h/week",
                averageROI: "250%"
              }}
            />
          </div>
        </div>
      </div>

        

      {/* How It Works */}
      <div className="py-20 bg-blue-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">
            Start In 3 Simple Steps
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <StepCard 
              number="1"
              title="Pick Trending Games"
              description="Use our AI to discover games predicted to trend in the next 7 days. High CPC, proven traffic potential."
            />
            <StepCard 
              number="2"
              title="One-Click Deploy"
              description="Choose template, customize content, and deploy. Everything is automated - DNS, SEO, Analytics."
            />
            <StepCard 
              number="3"
              title="Content and Backlink"
              description="Smart content performance monitoring, automatic quality content updates, and backlink optimization to boost traffic and conversions."
            />
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-8">Pricing Plans</h2>
          <PricingTable plans={pricingPlans} />
          <div className="mt-8 text-center">
            <p className="text-gray-600">
              All plans include: Core features, SEO optimization, and ad integration
            </p>
            <p className="text-gray-500 mt-2">
              Need custom solutions? <span className="text-blue-600 cursor-pointer">Contact us</span>
            </p>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-20 bg-blue-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">
            Common Questions
          </h2>
          <div className="max-w-3xl mx-auto space-y-8">
            <FAQItem 
              question="What technical preparation do I need?"
              answer="Almost none! You only need to purchase a domain name. We handle everything else including hosting, SEO optimization, and ad integration."
            />
            <FAQItem 
              question="How does the AI game trend prediction work?"
              answer="Our AI system analyzes global gaming data to identify games likely to trend in the next 7 days. New users receive 10 trending game recommendations to start with."
            />
            <FAQItem 
              question="What's included in the ¥99/site package?"
              answer="Each site includes: professional templates, automatic SEO optimization, built-in Google Ads integration, and technical support."
            />
          </div>
        </div>
      </div>

    

      {/* CTA Section */}
      <div className="py-20 bg-gradient-to-r from-blue-500 to-blue-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Ready to Start Your Gaming Website?
          </h2>

          <Link 
            href="/dashboard/website-builder"
            className="text-lg rounded-2xl px-8 py-4 bg-white text-blue-600
                       transition-all duration-300 shadow-lg hover:shadow-xl 
                       hover:scale-105 relative group"
          >
            <span className="relative z-10">{t('Home.cta.getStarted')}</span>
            <motion.span
              // @ts-ignore
              className="absolute inset-0 bg-white/10 rounded-lg"
              initial={{ scale: 0 }}
              whileHover={{ scale: 1 }}
              transition={{ duration: 0.3 }}
            />
            <span className="ml-2 group-hover:translate-x-1 transition-transform duration-300">→</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
